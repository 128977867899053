import React from "react";
import Urls from "../../../urls";
import PlanExpirationButton from "../../../sales/billing/PlanExpirationButton";
import Config from "../../../config";
import {Link} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import {AppContext, isLoggedIn} from "../../../context/global";
import ChangeLanguageButton from "../ChangeLanguageButton";

class NavbarRight extends React.Component {
    render() {
        const {i18n} = this.props;

        return (
            <ul className="nav navbar-nav navbar-right be-icons-nav">

                {(isLoggedIn() && !Config.isBrandEnabled) &&
                    <li className="hidden-xs hidden-sm">
                        <p className="navbar-btn" style={{paddingTop: '10px'}}>
                            <PlanExpirationButton />
                        </p>
                    </li>}

                {(isLoggedIn() && !Config.isBrandEnabled) &&
                    <li className="hidden-xs">
                        <Link to={Urls.inviting} role="button">
                            <span className="text-primary">
                                <i className="mdi mdi-card-giftcard"/>
                                &nbsp;
                                <span className="hidden-sm">
                                    {i18n.t('Convide seus amigos')}
                                </span>
                            </span>
                        </Link>
                    </li>}

                {(isLoggedIn() && !Config.isBrandEnabled) && !this.context.has_valid_paid_plan &&
                    <li className="dropdown">
                        <Link to={Urls.plans}>
                            <i className="mdi mdi-shopping-cart"/>
                            &nbsp;
                            {i18n.t('Preços')}
                        </Link>
                    </li>}

                {!Config.isBrandEnabled &&
                <li className="dropdown">
                    <a href={Urls.blog} role="button" aria-expanded="false" target="_blank">
                        <i className="mdi mdi-blogger"/>
                        &nbsp;
                        <span className="hidden-sm">
                            Blog
                        </span>
                    </a>
                </li>}

                <ChangeLanguageButton/>

                {!isLoggedIn() &&
                    <li className="dropdown">
                        <a href={`${Config.djangoHost}accounts/login`}>
                            <i className="icon mdi mdi-account"/>
                            &nbsp;
                            {i18n.t('Entrar')}
                        </a>
                    </li>}
            </ul>
        );
    }
}

NavbarRight.contextType = AppContext;

export default withNamespaces()(NavbarRight);